import { Icon, IconSizes, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import { useWindowWidth } from '@react-hook/window-size';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconShieldNotProtected } from '../../assets/icons/shieldNotProtected.svg';
import { ReactComponent as IconShieldProtected } from '../../assets/icons/shieldProtected.svg';
import Table from '../../components/Table/Table';
import { ETableAction, ETableIcon, ITableList } from '../../components/Table/Table.types';
import { ROUTE_FULL_TOTP } from '../../constants/routes';
import { getDateStr, isToday } from '../../utils/date';
import { ga4Event } from '../../utils/ga';
import styles from './Tables.module.scss';
import { IAuthInfoTable } from './Tables.types';

const styleRules: Record<string, string> = {
  '1': classNames(styles.threeColumnsTable_column1),
  '2': classNames(styles.threeColumnsTable_column2),
  '3': classNames(styles.threeColumnsTable_column3),
};
const AuthInfoTable: React.VFC<IAuthInfoTable> = ({ credentials, addPassword, editPassword, className }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  const addPasswordAction = useCallback(() => {
    ga4Event('securityPasswordClick', { placeholders: { element: 'add' } });
    addPassword();
  }, [addPassword]);
  const editPasswordAction = useCallback(() => {
    ga4Event('securityPasswordClick', { placeholders: { element: 'change' } });
    editPassword();
  }, [editPassword]);
  const totpAction = useCallback(() => {
    ga4Event('security2FaClick', undefined);
    navigate(ROUTE_FULL_TOTP);
  }, [navigate]);

  const list = useMemo<ITableList[]>(
    () => [
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('field.password')}
            </Text>
          ),
          '2': credentials.password?.enabled ? (
            <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
              {'••••••••'}
            </Text>
          ) : (
            <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
              {t('status.notAdded')}
            </Text>
          ),
          '3': (
            <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary}>
              {credentials.password?.enabled
                ? t('helper.lastChange', {
                    date: isToday(credentials.password.createdDate)
                      ? t('helper.today')
                      : getDateStr(credentials.password.createdDate, i18n.language),
                  })
                : t('action.addPassword')}
            </Text>
          ),
        },
        action: credentials.password?.enabled ? editPasswordAction : addPasswordAction,
      },
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('field.totp')}
            </Text>
          ),
          '2': credentials.totp?.length ? (
            <div className={styles.protection_secondColumn}>
              {windowWidth > 375 && (
                <Icon
                  IconComponent={IconShieldProtected}
                  size={IconSizes.l}
                  color={TextAndIconColors.actionConfirmTertiary}
                  className={styles.protection_icon}
                />
              )}
              <Text variant={TextVariants.header8} color={TextAndIconColors.actionConfirmTertiary}>
                {t('status.on')}
              </Text>
            </div>
          ) : (
            <div className={styles.protection_secondColumn}>
              {windowWidth > 375 && (
                <Icon
                  IconComponent={IconShieldNotProtected}
                  size={IconSizes.l}
                  color={TextAndIconColors.interfaceTertiary}
                  className={styles.protection_icon}
                />
              )}
              <Text variant={TextVariants.header8} color={TextAndIconColors.actionErrorSecondary}>
                {t('status.off')}
              </Text>
            </div>
          ),
          '3': '',
        },
        action: totpAction,
      },
    ],
    [addPasswordAction, credentials, editPasswordAction, i18n.language, t, totpAction, windowWidth],
  );

  return (
    <Table
      list={list}
      styleRules={styleRules}
      divider
      rowHover
      className={className}
      endIcon={ETableIcon.open}
      actionType={ETableAction.row}
      columns={['1', '2', '3']}
      classNameCells={styles.threeColumnsTable_cells}
    />
  );
};

export default AuthInfoTable;
