import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EButtonSizes,
  EInfoBlockVariant,
  Icon,
  IconSizes,
  InfoBlock,
  Link,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { useLoader } from 'library-react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconShieldProtected } from '../../assets/icons/shieldProtected.svg';
import { TOTP_MAX_QUANTITY } from '../../constants/totp';
import { ITotpCredential } from '../../hooks/store/store.types';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { getDateStr } from '../../utils/date';
import { ga4Event } from '../../utils/ga';
import { handleOn } from './Totp.functions';
import styles from './Totp.module.scss';

type TTotpOnProps = {
  totpCredentials: ITotpCredential[];
};
const TotpOn: React.VFC<TTotpOnProps> = ({ totpCredentials }) => {
  const { t, i18n } = useTranslation();
  const { loaderOn } = useLoader();
  const { startFlow } = useStartFlowStep();

  return (
    <div className={classNames(styles.page_block)}>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
        {t('page.totp.description.common')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__s}>
        {t('page.totp.description.remove1')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__s}>
        {t('page.totp.description.remove2')}
      </Text>
      {totpCredentials.length < TOTP_MAX_QUANTITY && (
        <Link
          underline={false}
          color={TextAndIconColors.interfaceAccentTertiary}
          variant={TextVariants.body2}
          className={styles.common_margin__s}
          onClick={() => handleOn(loaderOn, totpCredentials.length)}
        >
          {'+ ' + t('action.addDevice2fa')}
        </Link>
      )}
      {totpCredentials.length > TOTP_MAX_QUANTITY && (
        <InfoBlock
          variant={EInfoBlockVariant.error}
          title={t('page.totp.warning.title')}
          description={t('page.totp.warning.part1') + ' ' + t('page.totp.warning.part2')}
          className={styles.common_margin__s}
        />
      )}
      {totpCredentials.map((item, id) => (
        <div
          className={classNames(
            styles.page_block,
            styles.page_block__border,
            styles.this_wrapperBlock,
            styles.common_margin__m,
          )}
          key={id}
        >
          <div className={styles.page_iconBlock}>
            <Icon
              IconComponent={IconShieldProtected}
              size={IconSizes.xxl}
              color={TextAndIconColors.actionConfirmSecondary}
            />
            <div className={styles.page_block}>
              <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
                {t('field.deviceName', { name: item.userLabel })}
              </Text>
              <Text
                variant={TextVariants.body3}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_margin__xs}
              >
                {t('page.totp.deviceDate', { date: getDateStr(item.createdDate, i18n.language).toLowerCase() })}
              </Text>
              <Button
                type={ButtonTypes.button}
                text={t('action.deviceRemove').toString()}
                onClick={() => {
                  ga4Event('totpRemoveClick', undefined);
                  startFlow(EFlowType.deleteTotp, { totpId: item.id });
                }}
                variant={ButtonVariant.text}
                className={classNames(styles.common_margin__xs, styles.this_button)}
                theme={ButtonThemes.interfaceAccentSecondary}
                size={EButtonSizes.xs}
                style={{ padding: 0 }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotpOn;
