import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Icon,
  IconSizes,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/containers/Page';

import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { withMockModes } from '../../constants/mode';
import { ROUTE_FULL_PHONE_VIEW, ROUTE_SECURITY } from '../../constants/routes';
import AppContext from '../../context/AppContext';
import usePrivatePages from '../../hooks/privatePages.hook';
import useFlowPhones from '../../scenarios/flows/flowPhones.hook';
import { ga4Event } from '../../utils/ga';
import { phoneMask } from '../../utils/string';
import { withSlash } from '../../utils/url';
import styles from './PhoneAddComplete.module.scss';

const mockedPhone = '+7********88';

const PhoneAddComplete: React.VFC = () => {
  const { t } = useTranslation();
  const { phone, reset, fullPhone } = useFlowPhones();
  const { setPage } = usePrivatePages();
  const { mode } = useContext(AppContext);

  const toSecurity = useCallback(async () => {
    ga4Event('addPhoneCompleteClick', undefined);
    setPage(ROUTE_FULL_PHONE_VIEW, { available: false });
    await reset(withSlash(ROUTE_SECURITY));
  }, [reset, setPage]);

  // page.addPhoneSuccess.description не используется
  return (
    <Page menu subHead={{ title: t('page.addPhone.title') }} callback={reset}>
      <div className={styles.page_wrapper}>
        <div className={classNames(styles.page_block, styles.page_block__border)}>
          <div className={styles.this_iconBlock}>
            <Icon
              IconComponent={IconCheckmark}
              size={IconSizes.l}
              color={TextAndIconColors.actionConfirmPrimary}
              className={styles.page_iconBlock__icon}
            />
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
              {fullPhone ? t('page.phoneVerify.success') : t('page.addPhoneSuccess.subTitle')}
            </Text>
          </div>
          <Text
            variant={TextVariants.body1}
            color={TextAndIconColors.labelsSecondary}
            className={styles.common_margin__m}
          >
            {phoneMask(withMockModes.includes(mode) ? mockedPhone : phone)}
          </Text>
        </div>
        <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
          <Button
            text={t('action.toSecurity').toString()}
            type={ButtonTypes.button}
            onClick={toSecurity}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
        </div>
      </div>
    </Page>
  );
};

export default PhoneAddComplete;
