import classNames from 'classnames';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'src/context/AuthContext';

import CustomIcon from '../../components/CustomIcon';
import DropList from '../../components/DropList/DropList';
import Language from '../../components/Language';
import ProfileMenu from '../../components/ProfileMenu';
import { LocaleE } from '../../constants/locale.types';
import AppContext from '../../context/AppContext';
import useLanguages from '../../hooks/languages.hook';
import useLinkActions from '../../hooks/linkActions.hook';
import { ga4Event } from '../../utils/ga';
import styles from './Header.module.scss';

type THeaderType = 'form' | 'page';
type HeaderProps = {
  type: THeaderType;
  user: boolean;
  fixed?: boolean;
  callback?: () => void;
  clickable?: boolean;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({
  type,
  user,
  fixed = false,
  callback,
  clickable = true,
  className,
}: HeaderProps) => {
  const { t, i18n } = useTranslation();
  const { languageByCode } = useLanguages();
  const { config, supportedLocales } = useContext(AppContext);
  const { profile } = useContext(AuthContext);
  const linkAction = useLinkActions();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const logoClick = useCallback(() => {
    if (clickable) {
      callback?.();
      linkAction(config.logo.action);
    }
  }, [callback, clickable, config.logo, linkAction]);
  const userIconClick = useCallback(() => {
    ga4Event('userClick', undefined);
  }, []);
  const profileList = React.useMemo(
    () => config.profileMenu.list.map((item) => ({ ...item, text: t(item.text) })),
    [t, config.profileMenu],
  );
  const dropdownExists: boolean = config.profileMenu.list.length > 0;

  const classWrapper = classNames(
    styles.this_wrapper,
    {
      [styles.this_wrapper__fixed]: fixed,
      [styles.this_wrapper__form]: Boolean(type === 'form'),
    },
    className,
  );
  const classLogo = classNames(styles.this_logo, {
    [styles.this_logo__clickable]: clickable,
  });

  const UserIcon = (
    <CustomIcon
      icon={config.profileMenu.icon}
      size={38}
      onClick={userIconClick}
      color={config.styles?.theme.colors?.interfaceAdPrimary}
      initials={profile?.initials}
    />
  );

  const UserComponent = (): JSX.Element | null => {
    if (!user) {
      return null;
    }

    return dropdownExists ? (
      <DropList
        isOpen={isOpen}
        callback={onClose}
        arrow={false}
        component={UserIcon}
        dropdownClass={styles.this_userDropdown}
      >
        <ProfileMenu list={profileList} />
      </DropList>
    ) : (
      UserIcon
    );
  };

  return (
    <div className={classWrapper}>
      <div className={styles.this_container}>
        <div className={classLogo} onClick={logoClick}>
          <img className={styles.common_iconMargin__s} src={config.logo.image} alt='logo' />
        </div>
        <div className={styles.this_menu}>
          {supportedLocales.length > 1 && (
            <Language
              onCheck={(tag) => i18n.changeLanguage(tag)}
              currentTag={i18n.language as LocaleE}
              getLanguageByCode={languageByCode}
              supportedList={supportedLocales}
            />
          )}
          <UserComponent />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
