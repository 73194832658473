import 'moment/locale/ru';
import 'moment/locale/en-ca';
import 'moment/locale/de';
import 'moment/locale/pl';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import 'moment/locale/cs';
import 'moment/locale/uk';

import { capitalize } from 'lodash';
import moment from 'moment';

import { LocaleE } from '../constants/locale.types';

// https://momentjs.com/docs/#/i18n/loading-into-browser/

const localeSync = (locale: LocaleE): string => {
  switch (locale) {
    case LocaleE.ru: {
      return 'ru';
    }
    case LocaleE.de: {
      return 'de';
    }
    case LocaleE.es: {
      return 'es';
    }
    case LocaleE.pl: {
      return 'pl';
    }
    case LocaleE.pt: {
      return 'pt-br';
    }
    case LocaleE.cs: {
      return 'cs';
    }
    case LocaleE.uk: {
      return 'uk';
    }
    default: {
      return 'en-ca';
    }
  }
};

export const getDateStr = (iso: string | null | number = '', locale: string): string => {
  moment.locale(localeSync(locale as LocaleE));
  return moment(iso).format('D MMMM YYYY');
};

export const getMonths = (locale: string): { value: string; text: string }[] => {
  moment.locale(localeSync(locale as LocaleE));
  return moment.months().map((item, id) => ({ value: id.toString(), text: capitalize(item) }));
};

export const isoToObject = (iso: string): { day: number; month: number; year: number } => {
  const obj = moment(iso).toObject();
  // const date: Date = new Date(iso);
  return {
    day: obj.date,
    month: obj.months,
    year: obj.years,
  };
};

// месяц в объекте читается с нуля(видимо для селекта)
export const objToISO = (obj: { day: string; month: string; year: string }): string => {
  return moment({
    d: Number.parseInt(obj.day, 10),
    M: Number.parseInt(obj.month, 10),
    y: Number.parseInt(obj.year, 10),
  }).format('YYYY-MM-DD');
};

export const isValidDate = (iso: string): boolean => {
  return moment(iso).isValid();
};

export const isDayBeforeToday = (iso: string): boolean => {
  const date = moment(iso);
  const today = moment().endOf('day');
  return date < today;
};

export const isToday = (iso: string): boolean => {
  const date = moment(iso);
  return date < moment().endOf('day') && date > moment().startOf('day');
};

export const getSessionStr = (iso: string | undefined, locale: string): string => {
  if (!iso) return '';
  moment.locale(localeSync(locale as LocaleE));
  return moment(iso).format('DD.MM.YYYY HH:mm');
};
