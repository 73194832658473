import {
  Button,
  ButtonTypes,
  EInputThemes,
  Input,
  Link,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { useToast } from 'library-react-hooks';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/containers/Page';

import { API_ERROR_ADD_PHONE_CODE_RATE_LIMIT, API_ERROR_ADD_PHONE_WRONG_CODE } from '../../constants/api';
import { withMockModes } from '../../constants/mode';
import AppContext from '../../context/AppContext';
import useInput from '../../hooks/input.hook';
import useStore from '../../hooks/store';
import useFlowPhones from '../../scenarios/flows/flowPhones.hook';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { phoneMask } from '../../utils/string';
import { validationEmailCode } from '../../utils/validation';
import styles from './PhoneAddCode.module.scss';

const mockedPhone = '+7********88';

const PhoneAddCode: React.VFC = () => {
  const { t } = useTranslation();
  const { confirmCode, phone, reset, error, fullPhone } = useFlowPhones();
  const { mode } = useContext(AppContext);
  const { alert } = useToast();
  const { user } = useStore([]);

  const showVerifyVariant = Boolean(fullPhone) && !user.phoneVerified;

  const [code, changeCode, { error: errorCode, setError: setErrorCode }] = useInput<string>('', { translate: t });

  useEffect(() => {
    if (error?.code === API_ERROR_ADD_PHONE_WRONG_CODE) setErrorCode('message.errorValidationEmailCode');
    if (error?.code === API_ERROR_ADD_PHONE_CODE_RATE_LIMIT) {
      alert({
        type: 'error',
        titleData: {
          key: 'standardErrors.10028.title',
        },
        textData: {
          key: 'standardErrors.10028.text',
        },
      });
    }
  }, [alert, error?.code, setErrorCode]);
  const onSubmit = useCallback(async () => {
    let valid = true;
    if (!validationEmailCode(code)) {
      valid = false;
      setErrorCode('message.errorValidationEmailCode');
    }

    if (valid) {
      ga4Event('addPhoneCodeClick', undefined);
      await confirmCode(code);
    }
  }, [code, confirmCode, setErrorCode]);

  const buttonDisabled = code.length !== 6;

  useEffect(() => {
    const listener = listenKeydown('Enter', onSubmit, buttonDisabled);
    return () => {
      listener();
    };
  }, [onSubmit, buttonDisabled]);

  return (
    <Page menu subHead={{ title: t('page.addPhone.title') }} callback={reset}>
      <div className={styles.page_wrapper}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.addPhoneCode.description', { phone: phoneMask(withMockModes.includes(mode) ? mockedPhone : phone) })}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {showVerifyVariant ? t('page.phoneVerify.subTitle') : t('page.addPhoneCode.subTitle')}
          </Text>
          <Input
            placeholder={t('field.smsCode')}
            value={code}
            onChange={changeCode}
            className={classNames(styles.common_margin__l)}
            theme={errorCode ? EInputThemes.error : EInputThemes.base}
            errorMessage={errorCode}
            autoFocus
          />
          <Text
            variant={TextVariants.body2}
            color={TextAndIconColors.labelsQuaternary}
            className={classNames(styles.common_margin__m)}
          >
            {t('page.addPhoneCode.noSms') + ' '}
            <Link
              onClick={() => reset()}
              underline={false}
              color={TextAndIconColors.interfaceAccent}
              variant={TextVariants.body2}
            >
              {t('helper.restartFlow')}
            </Link>
          </Text>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.confirm').toString()}
              type={ButtonTypes.button}
              onClick={onSubmit}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PhoneAddCode;
