import { Button, ButtonTypes, ButtonVariant, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import passwordImage from '../../assets/images/password.png';
import passwordImageRetina from '../../assets/images/password@2x.png';
import { ga4Event } from '../../utils/ga';
import { isRetina } from '../../utils/retina';
import styles from './Notifications.module.scss';
import { INotification } from './Notifications.types';

const PasswordImage = isRetina() ? passwordImageRetina : passwordImage;

const NotificationPassword: React.VFC<INotification> = ({ className, onClick }) => {
  const { t } = useTranslation();

  const clickAction = useCallback(() => {
    ga4Event('securityAddPasswordNotificationClick', undefined);
    onClick?.();
  }, [onClick]);
  return (
    <div className={classNames(styles.notification_wrapper, className)}>
      <div className={styles.notification_content}>
        <Text
          variant={TextVariants.header5}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__xs}
        >
          {t('block.protectionPassword.title')}
        </Text>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsTertiary}
          className={styles.common_margin__xs}
        >
          {t('block.protectionPassword.description')}
        </Text>
        <Button
          text={t('action.addPassword').toString()}
          type={ButtonTypes.button}
          onClick={clickAction}
          variant={ButtonVariant.filled}
          className={styles.common_margin__l}
        />
      </div>
      <img className={styles.notification_image} src={PasswordImage} srcSet={passwordImage} alt='securityPassword' />
    </div>
  );
};

export default NotificationPassword;
