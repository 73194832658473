import {
  ROUTE_2FA,
  ROUTE_ADD,
  ROUTE_ALL,
  ROUTE_CHANGED,
  ROUTE_CODE,
  ROUTE_COMPLETE,
  ROUTE_CONFIRM,
  ROUTE_DELETE_ACCOUNT,
  ROUTE_DELETED,
  ROUTE_EDIT,
  ROUTE_EMAIL,
  ROUTE_FORMS,
  ROUTE_FULL_2FA,
  ROUTE_FULL_DELETE_ACCOUNT,
  ROUTE_FULL_DELETE_ACCOUNT_ALL,
  ROUTE_FULL_DELETE_ACCOUNT_CODE,
  ROUTE_FULL_DELETE_ACCOUNT_CONFIRM,
  ROUTE_FULL_DELETE_ACCOUNT_DELETED,
  ROUTE_FULL_DELETE_ACCOUNT_SENT, ROUTE_FULL_EMAIL,
  ROUTE_FULL_EMAIL_CHANGED,
  ROUTE_FULL_EMAIL_CODE,
  ROUTE_FULL_EMAIL_EDIT,
  ROUTE_FULL_EMAIL_SENT, ROUTE_FULL_EMAIL_VERIFIED, ROUTE_FULL_EMAIL_VERIFY,
  ROUTE_FULL_EMAIL_VIEW,
  ROUTE_FULL_PASSWORD,
  ROUTE_FULL_PASSWORD_ADD,
  ROUTE_FULL_PASSWORD_EDIT,
  ROUTE_FULL_PHONE_ADD,
  ROUTE_FULL_PHONE_ADD_CODE, ROUTE_FULL_PHONE_ADD_COMPLETE, ROUTE_FULL_PHONE_REMOVE_COMPLETE, ROUTE_FULL_PHONE_VIEW,
  ROUTE_FULL_RESET_TOTP_CODE,
  ROUTE_FULL_RESET_TOTP_COMPLETE,
  ROUTE_FULL_SESSIONS,
  ROUTE_FULL_SESSIONS_REMOVE_ALL,
  ROUTE_FULL_SESSIONS_REMOVE_PARTIAL,
  ROUTE_FULL_SOCIAL_ADD,
  ROUTE_FULL_SOCIAL_REMOVE,
  ROUTE_FULL_TOTP,
  ROUTE_FULL_TOTP_REMOVE,
  ROUTE_PARTIAL,
  ROUTE_PASSWORD,
  ROUTE_PERSONAL_DATA,
  ROUTE_PHONE,
  ROUTE_REMOVE,
  ROUTE_RESET_TOTP,
  ROUTE_SECURITY,
  ROUTE_SENT,
  ROUTE_SESSIONS,
  ROUTE_SOCIAL,
  ROUTE_TOTP, ROUTE_VERIFIED, ROUTE_VERIFY,
  ROUTE_VIEW,
} from '../constants/routes';
import { PagesRulesT } from '../hooks/privatePages.hook';

export const privatePagesConfig: PagesRulesT = {
  // Формы
  [ROUTE_FULL_PASSWORD]: {
    paths: [ROUTE_FORMS, ROUTE_PASSWORD],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_2FA]: {
    paths: [ROUTE_FORMS, ROUTE_2FA],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_EMAIL]: {
    paths: [ROUTE_FORMS, ROUTE_EMAIL],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  // Изменение email
  [ROUTE_FULL_EMAIL_VIEW]: {
    paths: [ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_VIEW],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_EMAIL_CODE]: {
    paths: [ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_CODE],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_EMAIL_EDIT]: {
    paths: [ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_EDIT],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_EMAIL_SENT]: {
    paths: [ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_SENT],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_EMAIL_CHANGED]: {
    paths: [ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_CHANGED],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  // Верификация email
  [ROUTE_FULL_EMAIL_VERIFY]: {
    paths: [ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_VERIFY],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_EMAIL_VERIFIED]: {
    paths: [ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_VERIFIED],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  // Манипуляции с паролем
  [ROUTE_FULL_PASSWORD_ADD]: {
    paths: [ROUTE_SECURITY, ROUTE_PASSWORD, ROUTE_ADD],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_PASSWORD_EDIT]: {
    paths: [ROUTE_SECURITY, ROUTE_PASSWORD, ROUTE_EDIT],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  // Соц. сети
  [ROUTE_FULL_SOCIAL_ADD]: {
    paths: [ROUTE_PERSONAL_DATA, ROUTE_SOCIAL, ROUTE_ADD],
    available: false,
    redirect: ROUTE_PERSONAL_DATA,
  },
  [ROUTE_FULL_SOCIAL_REMOVE]: {
    paths: [ROUTE_PERSONAL_DATA, ROUTE_SOCIAL, ROUTE_REMOVE],
    available: false,
    redirect: ROUTE_PERSONAL_DATA,
  },
  // Двухфакторка
  [ROUTE_FULL_TOTP_REMOVE]: {
    paths: [ROUTE_SECURITY, ROUTE_TOTP, ROUTE_REMOVE],
    available: false,
    redirect: ROUTE_FULL_TOTP,
  },
  // Удаление аккауунта
  [ROUTE_FULL_DELETE_ACCOUNT]: {
    paths: [ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT],
    available: true,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_DELETE_ACCOUNT_ALL]: {
    paths: [ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_ALL],
    available: true,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_DELETE_ACCOUNT_SENT]: {
    paths: [ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_SENT],
    available: false,
    redirect: ROUTE_FULL_DELETE_ACCOUNT,
  },
  [ROUTE_FULL_DELETE_ACCOUNT_CONFIRM]: {
    paths: [ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_CONFIRM],
    available: false,
    redirect: ROUTE_FULL_DELETE_ACCOUNT,
  },
  [ROUTE_FULL_DELETE_ACCOUNT_CODE]: {
    paths: [ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_CODE],
    available: false,
    redirect: ROUTE_FULL_DELETE_ACCOUNT,
  },
  [ROUTE_FULL_DELETE_ACCOUNT_DELETED]: {
    paths: [ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_DELETED],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  // Удаление сессий
  [ROUTE_FULL_SESSIONS_REMOVE_ALL]: {
    paths: [ROUTE_SECURITY, ROUTE_SESSIONS, ROUTE_REMOVE, ROUTE_ALL],
    available: false,
    redirect: ROUTE_FULL_SESSIONS,
  },
  [ROUTE_FULL_SESSIONS_REMOVE_PARTIAL]: {
    paths: [ROUTE_SECURITY, ROUTE_SESSIONS, ROUTE_REMOVE, ROUTE_PARTIAL],
    available: false,
    redirect: ROUTE_FULL_SESSIONS,
  },
  // Внешняя страница. Сброс 2фа
  [ROUTE_FULL_RESET_TOTP_CODE]: {
    paths: [ROUTE_RESET_TOTP, ROUTE_CODE],
    available: false,
    redirect: ROUTE_RESET_TOTP,
  },
  [ROUTE_FULL_RESET_TOTP_COMPLETE]: {
    paths: [ROUTE_RESET_TOTP, ROUTE_COMPLETE],
    available: false,
    redirect: ROUTE_RESET_TOTP,
  },
  // Добавление телефона
  [ROUTE_FULL_PHONE_ADD]: {
    paths: [ROUTE_SECURITY, ROUTE_PHONE, ROUTE_ADD],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_PHONE_ADD_CODE]: {
    paths: [ROUTE_SECURITY, ROUTE_PHONE, ROUTE_ADD, ROUTE_CODE],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_PHONE_ADD_COMPLETE]: {
    paths: [ROUTE_SECURITY, ROUTE_PHONE, ROUTE_ADD, ROUTE_COMPLETE],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  // Удаление телефона
  [ROUTE_FULL_PHONE_VIEW]: {
    paths: [ROUTE_SECURITY, ROUTE_PHONE, ROUTE_VIEW],
    available: false,
    redirect: ROUTE_SECURITY,
  },
  [ROUTE_FULL_PHONE_REMOVE_COMPLETE]: {
    paths: [ROUTE_SECURITY, ROUTE_PHONE, ROUTE_REMOVE, ROUTE_COMPLETE],
    available: false,
    redirect: ROUTE_FULL_PHONE_VIEW,
  },
};
