import { Button, ButtonTypes, Icon, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { generateLoginUrl } from '../../api/keycloak';
import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { ROUTE_SERVICES } from '../../constants/routes';
import Form from '../../containers/Form/Form';
import AppContext from '../../context/AppContext';
import useFlowResetTotp from '../../scenarios/flows/flowResetTotp.hook';
import { ga4Event } from '../../utils/ga';
import { openLink, withSlash } from '../../utils/url';
import styles from './ResetTotpComplete.module.scss';

const mockedLabel = 'My Device';

const toPassportServices = () => {
  ga4Event('resetTotpCompleteClick', undefined);
  openLink(generateLoginUrl(encodeURIComponent(`${process.env.REACT_APP_URL}${withSlash(ROUTE_SERVICES)}`)));
};

const ResetTotpComplete: React.VFC = () => {
  const { t } = useTranslation();
  const { label } = useFlowResetTotp();
  const { config } = React.useContext(AppContext);

  return (
    <Form supportEmail={config.supportEmail} user={false} logoClickable={false}>
      <div className={styles.form_wrapper}>
        <div className={styles.form_head}>
          <Icon IconComponent={IconCheckmark} height={42} width={42} color={TextAndIconColors.actionConfirmSecondary} />
          <Text
            variant={TextVariants.head3}
            color={TextAndIconColors.labelsSecondary}
            className={styles.common_margin__m}
          >
            {t('page.resetTotpComplete.title')}
          </Text>
        </div>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__l}
        >
          {t('page.resetTotpComplete.description', { deviceName: label || mockedLabel })}
        </Text>
        <div className={styles.common_buttons}>
          <Button text={t('action.login').toString()} type={ButtonTypes.button} onClick={toPassportServices} />
        </div>
      </div>
    </Form>
  );
};

export default ResetTotpComplete;
