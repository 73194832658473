import { generateTotpUrl } from '../../api/keycloak';
import { QUERY_PARAMS_TOTP_QUANTITY } from '../../constants/queryParams';
import { ROUTE_FULL_TOTP_ADD } from '../../constants/routes';
import { ga4Event } from '../../utils/ga';
import { openLink, withSlash } from '../../utils/url';

export const handleOn = (loaderOn: () => void, recentQuantity = 0): void => {
  ga4Event('totpAddClick', undefined);
  loaderOn();
  // GA4 BUG: Далее будет редирект и ГА4 не успевает отправиться
  setTimeout(() => {
    openLink(
      generateTotpUrl(
        encodeURIComponent(
          `${process.env.REACT_APP_URL}${withSlash(
            ROUTE_FULL_TOTP_ADD,
          )}/?${QUERY_PARAMS_TOTP_QUANTITY}=${recentQuantity}`,
        ),
      ),
    );
  }, 500);
};
