import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../components/Table/Table';
import { ETableAction, ETableIcon, ITableList } from '../../components/Table/Table.types';
import { ga4Event } from '../../utils/ga';
import { phoneMask, toUpperFirst } from '../../utils/string';
import styles from './Tables.module.scss';
import { IContactInfoTable } from './Tables.types';

const styleRules: Record<string, string> = {
  '1': classNames(styles.threeColumnsTable_column1),
  '2': classNames(styles.threeColumnsTable_column2),
  '3': classNames(styles.threeColumnsTable_column3),
};

const ContactInfoTable: React.VFC<IContactInfoTable> = ({
  user,
  readonly,
  changeEmail,
  className,
  addPhone,
  removePhone,
  addEmail,
  phoneManagement,
}) => {
  const { t } = useTranslation();

  const emailAction = useCallback(() => {
    ga4Event('securityEmailClick', undefined);
    if (user.emailVerified) {
      changeEmail();
    } else {
      addEmail();
    }
  }, [addEmail, changeEmail, user.emailVerified]);
  const phoneAction = useCallback(() => {
    ga4Event('securityPhoneClick', undefined);
    if (user.phone) {
      removePhone();
    } else {
      addPhone();
    }
  }, [addPhone, removePhone, user.phone]);

  const list = useMemo<ITableList[]>(
    () => [
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('field.email')}
            </Text>
          ),
          '2': (
            <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
              {user.email || ''}
            </Text>
          ),
          '3': user.email && !user.emailVerified && (
            <Text variant={TextVariants.header8} color={TextAndIconColors.actionErrorSecondary}>
              {t('status.noVerify')}
            </Text>
          ),
        },
        action: user.deleteRequestTimestamp || readonly ? undefined : emailAction,
      },
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('field.phone')}
            </Text>
          ),
          '2': (
            <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
              {user.phone ? phoneMask(user.phone) : toUpperFirst(t('status.notAdded'))}
            </Text>
          ),
          '3': user.phone && !user.phoneVerified && (
            <Text variant={TextVariants.header8} color={TextAndIconColors.actionErrorSecondary}>
              {t('status.noVerify')}
            </Text>
          ),
        },
        action: phoneAction,
        hide: !phoneManagement,
      },
    ],
    [emailAction, phoneAction, readonly, t, user, phoneManagement],
  );

  return (
    <Table
      list={list}
      styleRules={styleRules}
      divider
      rowHover
      className={className}
      endIcon={ETableIcon.open}
      actionType={ETableAction.row}
      columns={['1', '2', '3']}
      classNameCells={styles.threeColumnsTable_cells}
    />
  );
};

export default ContactInfoTable;
