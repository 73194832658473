import { ELinkTarget, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import { useDebounceCallback } from '@react-hook/debounce';
import classNames from 'classnames';
import { useToast } from 'library-react-hooks';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import securityImage from '../../assets/images/security.png';
import securityImageRetina from '../../assets/images/security@2x.png';
import { MODE_READONLY } from '../../constants/mode';
import { ROUTE_FULL_PHONE_VIEW, ROUTE_FULL_SESSIONS, ROUTE_FULL_TOTP } from '../../constants/routes';
import Page from '../../containers/Page';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { i18link } from '../../locales/formatters';
import { Notification2fa, NotificationDeleted, NotificationPassword } from '../../modules/Notifications';
import NotificationEmail from '../../modules/Notifications/NotificationEmail';
import NotificationPhone from '../../modules/Notifications/NotificationPhone';
import { AccountActionsTable, AuthInfoTable, ContactInfoTable, SessionsInfoTable } from '../../modules/Tables';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { ga4Event } from '../../utils/ga';
import { isRetina } from '../../utils/retina';
import styles from './Security.module.scss';

const SecurityImage = isRetina() ? securityImageRetina : securityImage;

const Security: React.VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { credentials, user, sessions, state } = useStore(['credentials', 'sessions']);
  const { mode, config } = useContext(AppContext);
  const { profile } = useContext(AuthContext);
  const { startFlow } = useStartFlowStep();
  const { alert } = useToast();

  const readonly = useMemo(() => mode === MODE_READONLY, [mode]);

  const blockedByEmail = useCallback(() => {
    alert({
      type: 'error',
      titleData: {
        key: 'standardErrors.10029.title',
      },
      textData: {
        key: 'standardErrors.10029.text',
      },
    });
  }, [alert]);
  const changeEmail = useDebounceCallback(
    async () => {
      if (user.id && !user.deleteRequestTimestamp) await startFlow(EFlowType.emailChange);
    },
    300,
    false,
  );
  const addPhone = useCallback(async () => {
    if (!user.emailVerified) {
      blockedByEmail();
      return;
    }
    await startFlow(EFlowType.addPhone);
  }, [blockedByEmail, startFlow, user.emailVerified]);
  const verifyPhone = useCallback(async () => {
    if (!user.emailVerified) {
      blockedByEmail();
      return;
    }
    await startFlow(EFlowType.verifyPhone, { fullPhone: profile?.phone });
  }, [blockedByEmail, profile?.phone, startFlow, user.emailVerified]);
  const removePhone = useCallback(() => {
    if (!user.emailVerified) {
      blockedByEmail();
      return;
    }
    navigate(ROUTE_FULL_PHONE_VIEW);
  }, [blockedByEmail, navigate, user.emailVerified]);
  const removePhoneNow = useCallback(async () => {
    await startFlow(EFlowType.removePhone);
  }, [startFlow]);
  const addPassword = useCallback(async () => {
    await startFlow(EFlowType.passwordAdd);
  }, [startFlow]);
  const editPassword = useCallback(async () => {
    await startFlow(EFlowType.passwordChange);
  }, [startFlow]);
  const endAll = useCallback(async () => {
    await startFlow(EFlowType.deleteSessions);
  }, [startFlow]);
  const endOne = useCallback(
    async (id: string) => {
      await startFlow(EFlowType.deleteSession, { sessionId: id });
    },
    [startFlow],
  );
  const addEmail = useCallback(async () => {
    await startFlow(EFlowType.emailAdd);
  }, [startFlow]);

  const Notification: JSX.Element | null = useMemo(() => {
    if (user.id && credentials.password) {
      if (user.deleteRequestTimestamp) {
        return <NotificationDeleted supportEmail={config.supportEmail} className={styles.common_margin__m} />;
      } else if (user.email && !user.emailVerified) {
        return <NotificationEmail className={styles.common_margin__m} onClick={addEmail} />;
      } else if (config.features.phoneManagement && user.phone && !user.phoneVerified) {
        return (
          <NotificationPhone
            className={styles.common_margin__m}
            onClick={verifyPhone}
            onReject={removePhoneNow}
            phone={user.phone}
          />
        );
      } else if (!credentials.password?.enabled) {
        return <NotificationPassword className={styles.common_margin__m} onClick={addPassword} />;
      } else if (credentials.totp?.length) {
        return null;
      } else {
        return <Notification2fa className={styles.common_margin__m} onClick={() => navigate(ROUTE_FULL_TOTP)} />;
      }
    } else return null;
  }, [
    addEmail,
    addPassword,
    config.features.phoneManagement,
    config.supportEmail,
    credentials,
    navigate,
    removePhoneNow,
    user,
    verifyPhone,
  ]);

  useOnLoad(Boolean(state.credentials && state.user && state.sessions), 'Security');

  return (
    <Page menu>
      <div className={styles.page_wrapper}>
        <div className={classNames(styles.page_imageBlock)}>
          <div className={styles.page_block}>
            <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
              {t('page.security.title')}
            </Text>
            <Text
              variant={TextVariants.body1}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('page.security.description')}
            </Text>
            {!readonly && config.documentationLinks.accountProtection && (
              <Link
                text={t('action.accountProtection')}
                href={i18link(config.documentationLinks.accountProtection)}
                color={TextAndIconColors.interfaceAccentSecondary}
                variant={TextVariants.body1}
                className={styles.common_margin__m}
                underline={false}
                target={ELinkTarget.blank}
                onClick={() => {
                  ga4Event('securityNotificationLinkClick', undefined);
                }}
              />
            )}
          </div>
          <img
            className={styles.page_imageBlock__image}
            src={SecurityImage}
            srcSet={securityImageRetina}
            alt='servicesImage'
          />
        </div>
        {Notification}
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <div className={styles.page_block}>
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
              {t(config.features.phoneManagement ? 'block.contactInfo.title' : 'block.contactInfo.titleWithoutPhone')}
            </Text>
            <Text
              variant={TextVariants.body2}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t(
                config.features.phoneManagement
                  ? 'block.contactInfo.description'
                  : 'block.contactInfo.descriptionWithoutPhone',
              )}
            </Text>
          </div>
          <ContactInfoTable
            className={styles.common_margin__xl}
            user={user}
            readonly={readonly}
            changeEmail={changeEmail}
            addPhone={addPhone}
            removePhone={removePhone}
            addEmail={addEmail}
            phoneManagement={config.features.phoneManagement}
          />
        </div>

        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <div className={classNames(styles.page_block)}>
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
              {t('block.authInfo.title')}
            </Text>
            {credentials.password?.enabled ? (
              <Text
                variant={TextVariants.body2}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_margin__xs}
              >
                {t('block.authInfo.description')}
              </Text>
            ) : (
              <Text
                variant={TextVariants.body2}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_margin__xs}
              >
                {t('block.authInfo.description2')}
              </Text>
            )}
          </div>
          <AuthInfoTable
            className={styles.common_margin__xl}
            credentials={credentials}
            addPassword={addPassword}
            editPassword={editPassword}
          />
        </div>

        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <div className={classNames(styles.page_block)}>
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
              {t('block.accountActions.title')}
            </Text>
            <Text
              variant={TextVariants.body2}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('block.accountActions.description')}
            </Text>
          </div>
          <AccountActionsTable className={styles.common_margin__xl} user={user} />
        </div>
        {!!sessions?.length && (
          <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
            <div className={classNames(styles.page_block)}>
              <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
                {t('block.sessions.title')}
              </Text>
              <Text
                variant={TextVariants.body2}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_margin__xs}
              >
                {t('block.sessions.description')}
              </Text>
              {sessions.length > 1 && (
                <Text
                  variant={TextVariants.body2}
                  color={TextAndIconColors.labelsTertiary}
                  className={styles.common_margin__m}
                >
                  {t('block.sessions.suspiciousIP') + ' '}
                  <Link
                    text={config.supportEmail}
                    href={'mailto:' + config.supportEmail}
                    underline={false}
                    color={TextAndIconColors.interfaceAccentTertiary}
                    variant={TextVariants.body3}
                  />
                </Text>
              )}
            </div>
            <SessionsInfoTable className={styles.common_margin__xl} sessions={sessions} deleteSession={endOne} />
            {sessions.length > 1 && (
              <Link
                underline={false}
                color={TextAndIconColors.interfaceAccentSecondary}
                onClick={endAll}
                variant={TextVariants.body2}
                className={styles.common_margin__s}
              >
                {t('action.endAllSessions')}
              </Link>
            )}
            <Link
              underline={false}
              color={TextAndIconColors.interfaceAccentSecondary}
              onClick={() => navigate(ROUTE_FULL_SESSIONS)}
              variant={TextVariants.body2}
              className={sessions.length > 1 ? styles.common_margin__xs : styles.common_margin__s}
            >
              {t('action.activityHistory')}
            </Link>
          </div>
        )}
      </div>
    </Page>
  );
};

export default React.memo(Security);
